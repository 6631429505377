@tailwind base;
@tailwind components;
@tailwind utilities;

ul li{
    -webkit-margin-start: 0.25rem;
            margin-inline-start: 0.25rem;
  }
  ul li:first-child{
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
  }


  /* Loader */
.loader {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20%;
        width: 80px;
        height: 80px;
        padding-left: 0;
      }
      .loader:after {
        content: ' ';
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #fa9c23;
        border-color: #fa9c23 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }